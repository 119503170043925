import React, { useState } from 'react';
import { Button, Text, FormControl, FormLabel, Input, Image, useColorMode, HStack, VStack, Icon, InputGroup, Box, Stack, InputLeftElement, SkeletonCircle, SkeletonText, Avatar, useToast,Checkbox, useBreakpointValue, MenuButton, Menu, MenuItem, MenuList } from '@chakra-ui/react';
import { BsCalendar2Date } from "react-icons/bs";
import { GiBodyHeight } from "react-icons/gi";
import { LiaWeightHangingSolid } from "react-icons/lia";
import av from './avatar.png';
import bar from './bar.png';
import { ChevronDownIcon } from '@chakra-ui/icons';

const CalorieCalculator = () => {
    const { colorMode } = useColorMode();
    const [gender, setGender] = useState('');
    const [age, setAge] = useState('');
    const [height, setHeight] = useState('');
    const [weight, setWeight] = useState('');
    const [activityLevel, setActivityLevel] = useState('Activity Level');
    const [bmr, setBmr] = useState('');
    const [calorieCalculation, setCalorieCalculation] = useState('');
    const [showRealCard, setShowRealCard] = useState(false);
    const toast = useToast();

    const isDesktop = useBreakpointValue({
        base: false,
        lg: true,
    });

    const handleGenderChange = (event) => {
        setGender(event.target.value);
    };

    const handleAgeChange = (event) => {
        setAge(event.target.value);
    };

    const handleHeightChange = (event) => {
        setHeight(event.target.value);
    };

    const handleWeightChange = (event) => {
        setWeight(event.target.value);
    };

    const handleActivityLevelChange = (event) => {
        setActivityLevel(event.target.textContent);
    };

    const calculateBMR = () => {
        if (age === '' || gender === '' || height === '' || weight === '' || activityLevel === '') {
            toast({
                description: "All fields are required",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            setShowRealCard(false);
            return;
        }

        let bmrCalc = 0;
        const heightInCm = parseInt(height);
        const weightInKg = parseFloat(weight);

                if (gender === 'male') {
            bmrCalc = 88.362 + (13.397 * weightInKg) + (4.799 * heightInCm) - (5.677 * parseInt(age));
        } else if (gender === 'female') {
            bmrCalc =  447.593 + (9.247 * weightInKg) + (3.098 * heightInCm) - (4.330 * parseInt(age));
        }

        // if (gender === 'male') {
        //     bmrCalc = (10 * weightInKg) + (6.25 * heightInCm) - (5 * parseInt(age) + 5);
        // } else if (gender === 'female') {
        //     bmrCalc = (10 * weightInKg) + (6.25 * heightInCm) - (5 * parseInt(age) - 161);
        // }

        let calorieCalculation = 0;
        switch (activityLevel) {
            case 'Little or no exercise':
                calorieCalculation = bmrCalc * 1.2;
                break;
            case 'Light exercise/sports 1-3 days​/week':
                calorieCalculation = bmrCalc * 1.375;
                break;
            case 'Moderate exercise/sports 3-5 days/week':
                calorieCalculation = bmrCalc * 1.55;
                break;
            case 'Hard exercise/sports 6-7 days a week':
                calorieCalculation = bmrCalc * 1.725;
                break;
            case 'Very hard exercise/sports & physical job or 2x training':
                calorieCalculation = bmrCalc * 1.9;
                break;
            default:
                break;
        }

        setCalorieCalculation(calorieCalculation.toFixed(0));
        setBmr(bmrCalc.toFixed(2));
        setShowRealCard(true);
    };

    return (
        
        // top={{ base: '', md: '85px', lg:'160px' }} 
        // right={{ base: '', md: '140px', lg:'240px' }}

        <VStack flex={1} bgColor={{ base:'#121212', md:'transparent'}}   
            position={{base :'', md:'absolute', md:'absolute'}}
            top={{ base: '', md: '85px' }} 
            right={{ base: '', md: '140px' }}
        >
            <Stack spacing={4} zIndex={2}  px={8} pt={4} pb={4} w={{md:'510px'}} bgColor={'#18181b'}  borderRadius={{base:'0px 0px 52px 52px', md:'52px'}} position={'relative'}>
                {/* {isDesktop && <Image src={bar} position={'absolute'} top={{base:'-30px', md: '-90px'}} left={{base:'-30px', md: '-90px'}} zIndex={33} width={{base:'100px', md: '190px'}}/>} */}
                <HStack bgColor={""} justifyContent={"center"} mb={2}>
                    <Text fontSize={'3xl'} className={'jakB'} color={'#6a00fc'}>Calorie Calculator</Text>
                </HStack>

                <HStack id="gender"  direction={'row'} bgColor={''} justifyContent="flex-start" >
                    <FormLabel mb={0} className={'jakM'} color={'#6a00fc'}>Gender :</FormLabel>
                    <HStack>
                        <Checkbox className={'jakR'} color={'#fff'}  colorScheme='purple' value="male" onChange={handleGenderChange} isChecked={gender === 'male'}>Male</Checkbox>
                        <Checkbox className={'jakR'} color={'#fff'}  colorScheme='purple' value="female" onChange={handleGenderChange} isChecked={gender === 'female'}>Female</Checkbox>
                    </HStack>
                </HStack>

                <FormControl id="age">
                    <FormLabel className={'jakM'} color={'#6a00fc'}>Age :</FormLabel>
                    <InputGroup bgColor="#5b01d6" borderRadius={'7px'}>
                        <InputLeftElement pointerEvents='none'>
                            <Icon as={BsCalendar2Date} color="#d9cdff" />
                        </InputLeftElement>
                        <Input type='number' id="age" placeholder='Age' color={'#fff'} value={age} onChange={handleAgeChange} 
                         _placeholder={{color :'#d9cdff'}}/>
                    </InputGroup>
                </FormControl>

                <HStack>
                    <FormControl id="height">
                        <FormLabel className={'jakM'} color={'#6a00fc'}>Height (cm):</FormLabel>
                        <InputGroup  bgColor="#5b01d6" borderRadius={'7px'}>
                            <InputLeftElement pointerEvents='none'>
                                <Icon as={GiBodyHeight} color="#d9cdff" />
                            </InputLeftElement>
                            <Input type="text" id="height" placeholder='Height' color={'#fff'} value={height} onChange={handleHeightChange}
                            _placeholder={{color :'#d9cdff'}}/>
                        </InputGroup>
                    </FormControl>

                    <FormControl id="weight">
                        <FormLabel className={'jakM'} color={'#6a00fc'}>Weight (Kg):</FormLabel>
                        <InputGroup  bgColor="#5b01d6" borderRadius={'7px'}>
                            <InputLeftElement pointerEvents='none'>
                                <Icon as={LiaWeightHangingSolid} color="#d9cdff" />
                            </InputLeftElement>
                            <Input type="text" id="weight" placeholder='Weight' color={'#fff'} value={weight} onChange={handleWeightChange}
                             _placeholder={{color :'#d9cdff'}}/>
                        </InputGroup>
                    </FormControl>
                </HStack>

                <Menu >
                <MenuButton as={Button} maxW={{sm:'sm', md:'inherit'}} className={'jakR'} fontWeight={'thin'} bgColor="#5b01d6" color={'#d9cdff'}
                    _hover={{ bg: '#4b03af', color:'#d9cdff' }} 
                    _expanded={{ bg: '#4b03af' }}
                    _focus={{ boxShadow: 'outline' }}
                    rightIcon={<ChevronDownIcon />} textAlign={'left'} >
                    {activityLevel.length > 20 ? activityLevel.slice(0, 32) + '...' : activityLevel}
                </MenuButton>
                    <MenuList bgColor="#242526" >
                        <MenuItem fontSize={'sm'} className={'jakR'} bgColor="#242526" color={'#d9cdff'}  _hover={{ bg: '#ebe4ff', color:'#2d0077' }} onClick={handleActivityLevelChange}>Little or no exercise</MenuItem>
                        <MenuItem fontSize={'sm'} className={'jakR'} bgColor="#242526" color={'#d9cdff'}  _hover={{ bg: '#ebe4ff', color:'#2d0077' }} onClick={handleActivityLevelChange}>Light exercise/sports 1-3 days​/week</MenuItem>
                        <MenuItem fontSize={'sm'} className={'jakR'} bgColor="#242526" color={'#d9cdff'}  _hover={{ bg: '#ebe4ff', color:'#2d0077' }} onClick={handleActivityLevelChange}>Moderate exercise/sports 3-5 days/week</MenuItem>
                        <MenuItem fontSize={'sm'} className={'jakR'} bgColor="#242526" color={'#d9cdff'}  _hover={{ bg: '#ebe4ff', color:'#2d0077' }} onClick={handleActivityLevelChange}>Hard exercise/sports 6-7 days a week</MenuItem>
                        <MenuItem fontSize={'sm'} className={'jakR'} bgColor="#242526" color={'#d9cdff'}  _hover={{ bg: '#ebe4ff', color:'#2d0077' }} onClick={handleActivityLevelChange}>Very hard exercise/sports & physical job or 2x training</MenuItem>
                    </MenuList>
                </Menu>

                <Button mt={3} className={'jakB'} rounded={'full'} size={'lg'} fontSize={'2xl'}  _hover={{ bg: '#4b03af', color:'#d9cdff' }} 
                bgColor={'#6a00fc'} color="white" px={6} onClick={calculateBMR}>
                    Calculate
                </Button>
            </Stack>

            
                <Stack spacing={4} zIndex={1} px={8} pt={'110px'} pb={4}  w={{ md:'510px'}} height={'200px'} bgColor={'#242526'}  borderRadius={'0px 0px 52px 52px'} boxShadow={'xl'} 
                transform={{ base: 'translateY(-97px)', md: 'translateY(-90px)' }}>
                {showRealCard === false ? 
                            <> 
                           <HStack spacing={4} bgColor={''}>
                                 <Box width={"60px"} height={'60px'}>
                                     <SkeletonCircle size="60px" />
                                 </Box>
                                 <VStack spacing="1" flex="1" alignItems={'flex-start'}>
                                     <Box bg={colorMode === "dark" ?  ''  : ''} width={{base:'200px', md:'250px'}}>
                                         <SkeletonText noOfLines={1} skeletonHeight='6' />
                                     </Box>
                                     <Box bg={colorMode === "dark" ?  ''  : ''} width={'100px'}>
                                         <SkeletonText mt='2' noOfLines={1} skeletonHeight='6' />
                                     </Box>
                                     
                                 </VStack>
                             </HStack>
                    </> : 
                            <>
                            <HStack spacing={4} bgColor={''}>
                                <Box width={"60px"} height={'60px'}>
                                    <Avatar size="60px" name='Warriors Athletics' src={av} />
                                </Box>
                                <VStack spacing="1" flex="1" alignItems={'flex-start'}>
                                    <Box bg={colorMode === "dark" ?  ''  : ''} >
                                        <Text  className='jakM' fontSize={'xl'} color={'white'} >
                                            {/* Basal Metabolic Rate */}
                                            Total daily calorie needs
                                        </Text>
                                    </Box>
                                    {/* <Box bg={colorMode === "dark" ?  ''  : ''} width={{base:'230px', md:'250px'}}>
                                        <Text  className='jakR' fontSize={'md'} color={'white'} >
                                            {bmr} BMR
                                        </Text>
                                    </Box> */}
                                    <Box bg={colorMode === "dark" ?  ''  : ''} width={{base:'230px', md:'250px'}}>
                                        <Text  className='jakR' fontSize={'md'} color={'white'} >
                                            {calorieCalculation}
                                        </Text>
                                    </Box>
                                </VStack>
                            </HStack>
                            </>
                }

                </Stack>
            

        </VStack>
    );
}

export default CalorieCalculator;



// import React, { useState } from 'react';
// import { Button, Text, FormControl, FormLabel, Input, Image, useColorMode, HStack, VStack, Icon, InputGroup, Box, Stack, InputLeftElement, SkeletonCircle, SkeletonText, Avatar, useToast,Checkbox, useBreakpointValue, MenuButton, Menu, MenuItem, MenuList } from '@chakra-ui/react';
// import { BsCalendar2Date } from "react-icons/bs";
// import { GiBodyHeight } from "react-icons/gi";
// import { LiaWeightHangingSolid } from "react-icons/lia";
// import av from './avatar.png';
// import bar from './bar.png';
// import { ChevronDownIcon } from '@chakra-ui/icons';

// const CalorieCalculator = () => {
//     const { colorMode } = useColorMode();
//     const [gender, setGender] = useState('');
//     const [age, setAge] = useState('');
//     const [height, setHeight] = useState('');
//     const [weight, setWeight] = useState('');
//     const [bmr, setBmr] = useState('');
//     const [showRealCard, setShowRealCard] = useState(false);
//     const toast = useToast();

//     const isDesktop = useBreakpointValue({
//         base: false,
//         lg: true,
//       }) 

//     const handleGenderChange = (event) => {
//         setGender(event.target.value);
//     };

//     const handleAgeChange = (event) => {
//         setAge(event.target.value);
//     };

//     const handleHeightChange = (event) => {
//         setHeight(event.target.value);
//     };

//     const handleWeightChange = (event) => {
//         setWeight(event.target.value);
//     };

//     const calculateBMR = () => {
//         if (age === '' || gender === '' || height === '' || weight === '') {
//             toast({
//                 description: "All fields are required",
//                 status: "error",
//                 duration: 5000,
//                 isClosable: true,
//             });
//             setShowRealCard(false);
//             return;
//         }

//         let bmrCalc = 0;
//         const heightInCm = parseInt(height);
//         const weightInKg = parseFloat(weight);


//         if (gender === 'male') {
//             bmrCalc =(10 * weightInKg) + (6.25 * heightInCm) - (5 * parseInt(age) + 5);
//         } else if (gender === 'female') {
//             bmrCalc =   (10 * weightInKg) + (6.25 * heightInCm) - (5 * parseInt(age) - 161);
//         }

//         setBmr(bmrCalc.toFixed(2));

       
//         setShowRealCard(true);
//     };

//     return (
//         <VStack flex={1} bgColor={{ base:'#121212', md:'transparent'}}   
//         position={{base :'', md:'absolute'}}
//         top={{ base: '', md: '85px' }} 
//         right={{ base: '', md: '140px' }}>
//             <Stack spacing={4} zIndex={2}  px={8} pt={4} pb={4} w={{md:'510px'}} bgColor={'#18181b'}  borderRadius={'52px'} position={'relative'}>
//                 {!isDesktop && <Image src={bar} position={'absolute'} top={{base:'-30px', md: '-90px'}} left={{base:'-30px', md: '-90px'}} zIndex={33} width={{base:'100px', md: '190px'}}/>}
//                 <HStack bgColor={""} justifyContent={"center"} mb={2}>
//                     <Text fontSize={'3xl'} className={'jakB'} color={'#6a00fc'}>Calorie Calculator</Text>
//                 </HStack>

//                 <HStack id="gender"  direction={'row'} bgColor={''} justifyContent="flex-start" >
//                     <FormLabel mb={0} className={'jakM'} color={'#6a00fc'}>Gender :</FormLabel>
//                     <HStack>
//                         <Checkbox className={'jakR'} color={'#fff'}  colorScheme='purple' value="male" onChange={handleGenderChange} isChecked={gender === 'male'}>Male</Checkbox>
//                         <Checkbox className={'jakR'} color={'#fff'}  colorScheme='purple' value="female" onChange={handleGenderChange} isChecked={gender === 'female'}>Female</Checkbox>
//                     </HStack>
//                 </HStack>

//                 <FormControl id="age">
//                     <FormLabel className={'jakM'} color={'#6a00fc'}>Age :</FormLabel>
//                     <InputGroup bgColor="#2d0077" borderRadius={'7px'}>
//                         <InputLeftElement pointerEvents='none'>
//                             <Icon as={BsCalendar2Date} color="#d9cdff" />
//                         </InputLeftElement>
//                         <Input type='number' id="age" placeholder='Age' color={'#fff'} value={age} onChange={handleAgeChange} 
//                          _placeholder={{color :'#d9cdff'}}/>
//                     </InputGroup>
//                 </FormControl>

//                 <HStack>
//                     <FormControl id="height">
//                         <FormLabel className={'jakM'} color={'#6a00fc'}>Height (cm):</FormLabel>
//                         <InputGroup  bgColor="#2d0077" borderRadius={'7px'}>
//                             <InputLeftElement pointerEvents='none'>
//                                 <Icon as={GiBodyHeight} color="#d9cdff" />
//                             </InputLeftElement>
//                             <Input type="text" id="height" placeholder='Height' color={'#fff'} value={height} onChange={handleHeightChange}
//                             _placeholder={{color :'#d9cdff'}}/>
//                         </InputGroup>
//                     </FormControl>

//                     <FormControl id="weight">
//                         <FormLabel className={'jakM'} color={'#6a00fc'}>Weight (Kg):</FormLabel>
//                         <InputGroup  bgColor="#2d0077" borderRadius={'7px'}>
//                             <InputLeftElement pointerEvents='none'>
//                                 <Icon as={LiaWeightHangingSolid} color="#d9cdff" />
//                             </InputLeftElement>
//                             <Input type="text" id="weight" placeholder='Weight' color={'#fff'} value={weight} onChange={handleWeightChange}
//                              _placeholder={{color :'#d9cdff'}}/>
//                         </InputGroup>
//                     </FormControl>
//                 </HStack>

//                 <Menu >
//                     <MenuButton as={Button} bgColor="#2d0077" 
//                          rightIcon={<ChevronDownIcon />} textAlign={'left'}>
//                         Activity Level
//                     </MenuButton>
//                     <MenuList>
//                         <MenuItem>little or no exercise</MenuItem>
//                         <MenuItem>light exercise/sports 1-3 days​/week</MenuItem>
//                         <MenuItem> moderate exercise/sports 3-5 days/week</MenuItem>
//                         <MenuItem>hard exercise/sports 6-7 days a week</MenuItem>
//                         <MenuItem>very hard exercise/sports & physical job or 2x training</MenuItem>
//                     </MenuList>
//                 </Menu>

//                 <Button mt={3} className={'jakB'} rounded={'full'} size={'lg'} fontSize={'2xl'}  bgColor={'#6a00fc'} color="white" px={6} onClick={calculateBMR}>
//                     Calculate
//                 </Button>
//             </Stack>

            
//                 <Stack spacing={4} zIndex={1} px={8} pt={'110px'} pb={4}  w={{ md:'510px'}} height={'200px'} bgColor={'#242526'}  borderRadius={'0px 0px 52px 52px'} boxShadow={'xl'} 
//                 transform={{ base: 'translateY(-97px)', md: 'translateY(-90px)' }}>
//                 {showRealCard === false ? 
//                             <> 
//                            <HStack spacing={4} bgColor={''}>
//                                  <Box width={"60px"} height={'60px'}>
//                                      <SkeletonCircle size="60px" />
//                                  </Box>
//                                  <VStack spacing="1" flex="1" alignItems={'flex-start'}>
//                                      <Box bg={colorMode === "dark" ?  ''  : ''} width={'100px'}>
//                                          <SkeletonText mt='2' noOfLines={1} skeletonHeight='6' />
//                                      </Box>
//                                      <Box bg={colorMode === "dark" ?  ''  : ''} width={{base:'230px', md:'250px'}}>
//                                          <SkeletonText noOfLines={1} skeletonHeight='6' />
//                                      </Box>
//                                  </VStack>
//                              </HStack>
//                     </> : 
//                             <>
//                             <HStack spacing={4} bgColor={''}>
//                                 <Box width={"60px"} height={'60px'}>
//                                     <Avatar size="60px" name='Warriors Athletics' src={av} />
//                                 </Box>
//                                 <VStack spacing="1" flex="1" alignItems={'flex-start'}>
//                                     <Box bg={colorMode === "dark" ?  ''  : ''} >
//                                         <Text  className='jakM' fontSize={'xl'} color={'white'} >
//                                             Basal Metabolic Rate
//                                         </Text>
//                                     </Box>
//                                     <Box bg={colorMode === "dark" ?  ''  : ''} width={{base:'230px', md:'250px'}}>
//                                         <Text  className='jakR' fontSize={'md'} color={'white'} >
//                                             {bmr} Calories/day
//                                         </Text>
//                                     </Box>
//                                 </VStack>
//                             </HStack>
//                             </>
//                 }

//                 </Stack>
            

//         </VStack>
//     );
// }

// export default CalorieCalculator;






// import React, { useState } from 'react';
// import { Button, Text, FormControl, FormLabel, Input, Image, useColorMode, HStack, VStack, Icon, InputGroup, Box, Stack, InputLeftElement, SkeletonCircle, SkeletonText, Avatar, useToast,Checkbox, useBreakpointValue, MenuButton, Menu, MenuItem, MenuList } from '@chakra-ui/react';
// import { BsCalendar2Date } from "react-icons/bs";
// import { GiBodyHeight } from "react-icons/gi";
// import { LiaWeightHangingSolid } from "react-icons/lia";
// import av from './avatar.png';
// import bar from './bar.png';
// import { ChevronDownIcon } from '@chakra-ui/icons';

// const CalorieCalculator = () => {
//     const { colorMode } = useColorMode();
//     const [gender, setGender] = useState('');
//     const [age, setAge] = useState('');
//     const [height, setHeight] = useState('');
//     const [weight, setWeight] = useState('');
//     const [bmr, setBmr] = useState('');
//     const [showRealCard, setShowRealCard] = useState(false);
//     const toast = useToast();

//     const isDesktop = useBreakpointValue({
//         base: false,
//         lg: true,
//       }) 

//     const handleGenderChange = (event) => {
//         setGender(event.target.value);
//     };

//     const handleAgeChange = (event) => {
//         setAge(event.target.value);
//     };

//     const handleHeightChange = (event) => {
//         setHeight(event.target.value);
//     };

//     const handleWeightChange = (event) => {
//         setWeight(event.target.value);
//     };

//     const calculateBMR = () => {
//         if (age === '' || gender === '' || height === '' || weight === '') {
//             setError('All fields are required');
//             toast({
//                 description: "All fields are required",
//                 status: "error",
//                 duration: 5000,
//                 isClosable: true,
//             });
//             setShowRealCard(false);
//             return;
//         }

//         let bmrCalc = 0;
//         const heightInCm = parseInt(height);
//         const weightInKg = parseFloat(weight);

        

//         if (gender === 'male') {
//             bmrCalc = 88.362 + (13.397 * weightInKg) + (4.799 * heightInCm) - (5.677 * parseInt(age));
//         } else if (gender === 'female') {
//             bmrCalc =  447.593 + (9.247 * weightInKg) + (3.098 * heightInCm) - (4.330 * parseInt(age));
//         }

//         setBmr(bmrCalc.toFixed(2));

       
//         setShowRealCard(true);
//     };

//     return (
//         <VStack flex={1} bgColor={{ base:'#121212', md:'transparent'}}   
//         position={{base :'', md:'absolute'}}
//         top={{ base: '', md: '85px' }} 
//         right={{ base: '', md: '140px' }}>
//             <Stack spacing={4} zIndex={2}  px={8} pt={4} pb={4} w={{md:'510px'}} bgColor={'#18181b'}  borderRadius={'52px'} position={'relative'}>
//                 {!isDesktop && <Image src={bar} position={'absolute'} top={{base:'-30px', md: '-90px'}} left={{base:'-30px', md: '-90px'}} zIndex={33} width={{base:'100px', md: '190px'}}/>}
//                 <HStack bgColor={""} justifyContent={"center"} mb={2}>
//                     <Text fontSize={'3xl'} className={'jakB'} color={'#6a00fc'}>Calorie Calculator</Text>
//                 </HStack>

//                 <HStack id="gender"  direction={'row'} bgColor={''} justifyContent="flex-start" >
//                     <FormLabel mb={0} className={'jakM'} color={'#6a00fc'}>Gender :</FormLabel>
//                     <HStack>
//                         <Checkbox className={'jakR'} color={'#fff'}  colorScheme='purple' value="male" onChange={handleGenderChange} isChecked={gender === 'male'}>Male</Checkbox>
//                         <Checkbox className={'jakR'} color={'#fff'}  colorScheme='purple' value="female" onChange={handleGenderChange} isChecked={gender === 'female'}>Female</Checkbox>
//                     </HStack>
//                 </HStack>

//                 <FormControl id="age">
//                     <FormLabel className={'jakM'} color={'#6a00fc'}>Age :</FormLabel>
//                     <InputGroup bgColor="#2d0077" borderRadius={'7px'}>
//                         <InputLeftElement pointerEvents='none'>
//                             <Icon as={BsCalendar2Date} color="#d9cdff" />
//                         </InputLeftElement>
//                         <Input type='number' id="age" placeholder='Age' color={'#fff'} value={age} onChange={handleAgeChange} 
//                          _placeholder={{color :'#d9cdff'}}/>
//                     </InputGroup>
//                 </FormControl>

//                 <HStack>
//                     <FormControl id="height">
//                         <FormLabel className={'jakM'} color={'#6a00fc'}>Height (cm):</FormLabel>
//                         <InputGroup  bgColor="#2d0077" borderRadius={'7px'}>
//                             <InputLeftElement pointerEvents='none'>
//                                 <Icon as={GiBodyHeight} color="#d9cdff" />
//                             </InputLeftElement>
//                             <Input type="text" id="height" placeholder='Height' color={'#fff'} value={height} onChange={handleHeightChange}
//                             _placeholder={{color :'#d9cdff'}}/>
//                         </InputGroup>
//                     </FormControl>

//                     <FormControl id="weight">
//                         <FormLabel className={'jakM'} color={'#6a00fc'}>Weight (Kg):</FormLabel>
//                         <InputGroup  bgColor="#2d0077" borderRadius={'7px'}>
//                             <InputLeftElement pointerEvents='none'>
//                                 <Icon as={LiaWeightHangingSolid} color="#d9cdff" />
//                             </InputLeftElement>
//                             <Input type="text" id="weight" placeholder='Weight' color={'#fff'} value={weight} onChange={handleWeightChange}
//                              _placeholder={{color :'#d9cdff'}}/>
//                         </InputGroup>
//                     </FormControl>
//                 </HStack>

//                 <Menu >
//                     <MenuButton as={Button} bgColor="#2d0077" 
//                          rightIcon={<ChevronDownIcon />} textAlign={'left'}>
//                         Activity Level
//                     </MenuButton>
//                     <MenuList>
//                         <MenuItem>little or no exercise</MenuItem>
//                         <MenuItem>light exercise/sports 1-3 days​/week</MenuItem>
//                         <MenuItem> moderate exercise/sports 3-5 days/week</MenuItem>
//                         <MenuItem>hard exercise/sports 6-7 days a week</MenuItem>
//                         <MenuItem>very hard exercise/sports & physical job or 2x training</MenuItem>
//                     </MenuList>
//                 </Menu>

//                 <Button mt={3} className={'jakB'} rounded={'full'} size={'lg'} fontSize={'2xl'}  bgColor={'#6a00fc'} color="white" px={6} onClick={calculateBMR}>
//                     Calculate
//                 </Button>
//             </Stack>

            
//                 <Stack spacing={4} zIndex={1} px={8} pt={'110px'} pb={4}  w={{ md:'510px'}} height={'200px'} bgColor={'#242526'}  borderRadius={'0px 0px 52px 52px'} boxShadow={'xl'} 
//                 transform={{ base: 'translateY(-97px)', md: 'translateY(-90px)' }}>
//                 {showRealCard === false ? 
//                             <> 
//                            <HStack spacing={4} bgColor={''}>
//                                  <Box width={"60px"} height={'60px'}>
//                                      <SkeletonCircle size="60px" />
//                                  </Box>
//                                  <VStack spacing="1" flex="1" alignItems={'flex-start'}>
//                                      <Box bg={colorMode === "dark" ?  ''  : ''} width={'100px'}>
//                                          <SkeletonText mt='2' noOfLines={1} skeletonHeight='6' />
//                                      </Box>
//                                      <Box bg={colorMode === "dark" ?  ''  : ''} width={{base:'230px', md:'250px'}}>
//                                          <SkeletonText noOfLines={1} skeletonHeight='6' />
//                                      </Box>
//                                  </VStack>
//                              </HStack>
//                     </> : 
//                             <>
//                             <HStack spacing={4} bgColor={''}>
//                                 <Box width={"60px"} height={'60px'}>
//                                     <Avatar size="60px" name='Warriors Athletics' src={av} />
//                                 </Box>
//                                 <VStack spacing="1" flex="1" alignItems={'flex-start'}>
//                                     <Box bg={colorMode === "dark" ?  ''  : ''} >
//                                         <Text  className='jakM' fontSize={'xl'} color={'white'} >
//                                             Basal Metabolic Rate
//                                         </Text>
//                                     </Box>
//                                     <Box bg={colorMode === "dark" ?  ''  : ''} width={{base:'230px', md:'250px'}}>
//                                         <Text  className='jakR' fontSize={'md'} color={'white'} >
//                                             {bmr} Calories/day
//                                         </Text>
//                                     </Box>
//                                 </VStack>
//                             </HStack>
//                             </>
//                 }

//                 </Stack>
            

//         </VStack>
//     );
// }

// export default CalorieCalculator;

