import { useEffect, useState } from 'react'
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

import Home from "./components/pages/home/Home";
import { Box } from '@chakra-ui/react';



function App() {




  return (
    <>
    <Router>
      <Box className="App">
        <Routes>
            <Route path='/' element={<Home/>}/>
            
            <Route path="*" element={<Navigate to='/'/>} />
        </Routes>
      </Box>
    </Router>
      

    </>
  );
}

export default App;