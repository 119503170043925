import {
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  Image,
  useBreakpointValue,
  useColorModeValue,
  Container
} from '@chakra-ui/react';
import * as React from 'react';
import { FiMenu, FiX } from 'react-icons/fi';
import MobileMenu from './MobileMenu';
import { Link, useLocation  } from 'react-router-dom';
import Logo from './warriorWhite.svg';
import { Link as LinkScroll } from 'react-scroll';
import { MoonIcon, SunIcon } from "@chakra-ui/icons";
import { useColorMode } from "@chakra-ui/color-mode";
import { BsInstagram } from 'react-icons/bs';






const Navbar = () => {
  const isDesktop = useBreakpointValue({
    base: false,
    lg: true,
  });

  const { colorMode, toggleColorMode } = useColorMode();


   const location = useLocation();
  const isContactOrDirectorPath = location.pathname === '/contact' || location.pathname === '/mot-du-directeur';


  const [isScrolled, setIsScrolled] = React.useState(false);

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const bgColor =
  isScrolled && colorMode === 'dark'
    ?  'rgba(26, 32, 44, 0.8)' // Adjust the RGB values and the alpha value for the desired color and transparency
    : isScrolled
    ? 'rgba(45, 0, 119, 0.6)' 
    : 'transparent';

  return (
    <Box
      as="nav"
      position="sticky"
      top="0"
      zIndex="100"
      // bg={isScrolled ? 'rgba(255, 255, 255, 0.8)' : 'transparent'}
      // bg={isScrolled && colorMode === 'dark' ? 'blue.900' : isScrolled ? 'rgba(255, 255, 255, 0.8)' : 'transparent'}
      bg={bgColor}

      backdropFilter={isScrolled ? 'blur(4px)' : 'none'}
      transition="all 0.3s ease"
      boxShadow={isScrolled ? 'sm' : 'none'}
    >
      <Container as="section" maxW="7xl" py={{ base: '3', lg: '4' }}>
        <Flex justify="space-between" align="center">
          
          <HStack spacing={4}>
            <Link to="/" aria-label="Home">
              <Image src={Logo} alt="Logo" height="10" width="auto" />
            </Link>
          </HStack>
           {isDesktop ? (
            <HStack spacing={4}>
              {!isContactOrDirectorPath && (
                <>
                  {/* <LinkScroll to="NosMetiers" aria-label="NosMetiers" spy={true} smooth={true} offset={-70} duration={2000}>
                    <Button variant={'solid'} colorScheme={'green'}>
                      Nos Métiers
                    </Button>
                  </LinkScroll> */}
                  
                  {/* <Link to={`/contact`}>
                  <Button color={"white"} bgColor={'#AFB5BF'} _hover={{ bg: '#959AA0' }}>
                    Contactez-nous
                  </Button>
                  </Link> */}
                  {/* <Link to={`/makeOrder`}>
                  <Button color={"white"} bgColor={'#AFB5BF'} _hover={{ bg: '#959AA0' }}>
                    Make Order
                  </Button>
                  </Link> */}
                  {/* <Button onClick={() => toggleColorMode()}  >
                    {colorMode === "dark" ? (
                      <SunIcon color="orange.200" />
                    ) : (
                      <MoonIcon color="blue.700" />
                    )}
                  </Button> */}

              <IconButton
              isRound
              variant="solid"
              icon={ <BsInstagram fontSize="1.25rem" />}
              aria-label="Toggle Menu"
              onClick={() => {
                const instagramUrl = `https://www.instagram.com/zakaria_ben_sghir/`;
                window.open(instagramUrl, '_blank');
              }}
            />
                </>
              )}
              
            </HStack>
          ) : (
            <IconButton
              variant="solid"
              icon={ <BsInstagram fontSize="1.25rem" />}
              aria-label="Toggle Menu"
              onClick={() => {
                const instagramUrl = `https://www.instagram.com/zakaria_ben_sghir/`;
                window.open(instagramUrl, '_blank');
              }}
            />
          )}
        </Flex>
        {isMenuOpen && (
          <Box mt={4}>
            <MobileMenu />
          </Box>
        )}
      </Container>
    </Box>
  );
};

export default Navbar;




// import {
//   Box,
//   Button,
//   Flex,
//   HStack,
//   IconButton,
//   Image,
//   useBreakpointValue,
//   useColorModeValue,
//   Container
// } from '@chakra-ui/react';
// import * as React from 'react';
// import { FiMenu, FiX } from 'react-icons/fi';
// import MobileMenu from './MobileMenu';
// import { Link, useLocation  } from 'react-router-dom';
// import Logo from './warriorWhite.svg';
// import { Link as LinkScroll } from 'react-scroll';
// import { MoonIcon, SunIcon } from "@chakra-ui/icons";
// import { useColorMode } from "@chakra-ui/color-mode";
// import { BsInstagram } from 'react-icons/bs';






// const Navbar = () => {
//   const isDesktop = useBreakpointValue({
//     base: false,
//     lg: true,
//   });

//   const { colorMode, toggleColorMode } = useColorMode();


//    const location = useLocation();
//   const isContactOrDirectorPath = location.pathname === '/contact' || location.pathname === '/mot-du-directeur';


//   const [isScrolled, setIsScrolled] = React.useState(false);

//   const handleScroll = () => {
//     if (window.scrollY > 0) {
//       setIsScrolled(true);
//     } else {
//       setIsScrolled(false);
//     }
//   };

//   React.useEffect(() => {
//     window.addEventListener('scroll', handleScroll);
//     return () => window.removeEventListener('scroll', handleScroll);
//   }, []);

//   const [isMenuOpen, setIsMenuOpen] = React.useState(false);

//   const handleMenuToggle = () => {
//     setIsMenuOpen(!isMenuOpen);
//   };

//   const bgColor =
//   isScrolled && colorMode === 'dark'
//     ?  'rgba(26, 32, 44, 0.8)' // Adjust the RGB values and the alpha value for the desired color and transparency
//     : isScrolled
//     ? 'rgba(45, 0, 119, 0.6)' 
//     : 'transparent';

//   return (
//     <Box
//       as="nav"
//       position="sticky"
//       top="0"
//       zIndex="100"
//       // bg={isScrolled ? 'rgba(255, 255, 255, 0.8)' : 'transparent'}
//       // bg={isScrolled && colorMode === 'dark' ? 'blue.900' : isScrolled ? 'rgba(255, 255, 255, 0.8)' : 'transparent'}
//       bg={bgColor}

//       backdropFilter={isScrolled ? 'blur(4px)' : 'none'}
//       transition="all 0.3s ease"
//       boxShadow={isScrolled ? 'sm' : 'none'}
//     >
//       <Container as="section" maxW="7xl" py={{ base: '3', lg: '4' }}>
//         <Flex justify="space-between" align="center">
          
//           <HStack spacing={4}>
//             <Link to="/" aria-label="Home">
//               <Image src={Logo} alt="Logo" height="10" width="auto" />
//             </Link>
//           </HStack>
//            {isDesktop ? (
//             <HStack spacing={4}>
//               {!isContactOrDirectorPath && (
//                 <>
//                   {/* <LinkScroll to="NosMetiers" aria-label="NosMetiers" spy={true} smooth={true} offset={-70} duration={2000}>
//                     <Button variant={'solid'} colorScheme={'green'}>
//                       Nos Métiers
//                     </Button>
//                   </LinkScroll> */}
                  
//                   {/* <Link to={`/contact`}>
//                   <Button color={"white"} bgColor={'#AFB5BF'} _hover={{ bg: '#959AA0' }}>
//                     Contactez-nous
//                   </Button>
//                   </Link> */}
//                   {/* <Link to={`/makeOrder`}>
//                   <Button color={"white"} bgColor={'#AFB5BF'} _hover={{ bg: '#959AA0' }}>
//                     Make Order
//                   </Button>
//                   </Link> */}
//                   {/* <Button onClick={() => toggleColorMode()}  >
//                     {colorMode === "dark" ? (
//                       <SunIcon color="orange.200" />
//                     ) : (
//                       <MoonIcon color="blue.700" />
//                     )}
//                   </Button> */}

//                   <Button onClick={() => {
//                     const instagramUrl = `https://www.instagram.com/zakaria_ben_sghir/`;
//                     window.open(instagramUrl, '_blank');
//                   }}  >
                   
//                       <BsInstagram color="orange.200" />
                    
//                   </Button>
//                 </>
//               )}
              
//             </HStack>
//           ) : (
//             <IconButton
//               variant="ghost"
//               icon={isMenuOpen ? <FiX fontSize="1.25rem" /> : <FiMenu fontSize="1.25rem" />}
//               aria-label="Toggle Menu"
//               onClick={handleMenuToggle}
//             />
//           )}
//         </Flex>
//         {isMenuOpen && (
//           <Box mt={4}>
//             <MobileMenu />
//           </Box>
//         )}
//       </Container>
//     </Box>
//   );
// };

// export default Navbar;

