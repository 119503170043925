
import React, { useEffect } from 'react';
import 'swiper/swiper-bundle.css';
import {
  Stack,
  Button,
  Flex,
  HStack,
  IconButton,
  Image,
  useBreakpointValue,
  useColorModeValue,
  Container
} from '@chakra-ui/react';

import Navbar from '../../navbar/Navbar'
import Hero from './Hero'

import './home.css'
import CalorieCalculator from '../../elements/calorieCalculator/CalorieCalculator';
import { BsInstagram, BsWhatsapp } from 'react-icons/bs';




const Home = () => {


  return (
    <>
        {/* <IconButton isRound={true} variant='solid' colorScheme='purple' aria-label='Instagram' fontSize='28px' size='lg' icon={<BsInstagram />} position='fixed' bottom='20px'  right='20px'  zIndex={999}
            onClick={() => {
              const instagramUrl = `https://www.instagram.com/zakaria_ben_sghir/`;
              window.open(instagramUrl, '_blank');
            }}
        /> */}
        <Hero />

        {/* <CalorieCalculator/> */}
        {/* <HowItWorks/> */}
        {/* <NosMetiers/> */}
        {/* <Travail /> */}
        {/* <OurService/> */}
        {/* <StatsOnBrand/> */}
        {/* <Equipe/> */}
        {/* <Newsletter/> */}
        {/* <Footer/> */}
        
    </>
  )
}

export default Home